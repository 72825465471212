import React, { useState, useEffect } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className={`nav-wrapper ${scrolled ? 'scrolled' : ''}`}>
        <div className="nav-content">
          <a className="logo" href="">
            Valens <span>Code</span>
          </a>
          <ul>
            <li>
              <a className="menu-item" href="#projects">
                Proyects
              </a>
            </li>

            <li>
              <a className="menu-item" href="#about">
                <span className="aboutspan">About Me</span>
              </a>
            </li>

            <li>
              <a className="menu-item" href="#Contactme">
                Contact Me
              </a>
            </li>

            <button
              className="contact-btn"
              onClick={() => {
                window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLSd4g7YadmbjPTxSHaGSxtUXh4E4yXP9Y4rlG47XJ6ZIb7nknA/viewform";
              }}
            >
              Hire Me
            </button>
          </ul>

          <button className="menu-btn" onClick={toggleMenu}>
            {openMenu ? (
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAhZJREFUSEu1V+11wyAMPG2SbhJPUmeSjuJ2EnuTehMaQAZhJJm8tv6Vx9dJp9NBCP/8EYCgYMRx9asbrK287WI6rUpr5EJKQzzxV6mPRSKAPVIOskYPDUxvzOz43SZmUv1X+XfncOwNcFeK36C75Bw1lgCtBpIkFW1UqV4A1AOklF4Rl6CoEagS9JASBhbdCPgIhC8EbBfr7wAWAG85HoU+0YVGe5e2Xwm4B8KOgAeAzSh9BF0Zbg8F3PYJpY9lG9AMhJhF/HbgBJ6TKqC8bgJha02jNZzGQHQa0+jMFGrgPajBSkN8VSxn2aGXAQ08BrIWyghT1IHVJLLsvYEoadchmglh4ervBNzy7xT0FFKmUv62EZhe7ahXZn6cPD0FuB1B6PdR6wcvW2ZSOKtXCq4C+1lK12/lVqnT7tGzkA5mI+2PkJSsNklHAr3gz5p6b1nt6ZS21S6cZkhcT9NQQGljH54RsHACyWQk7Rb+QB/jTsAqaqOol00mo+TMmfZzC7U1ti017vsGECmNbTMBwbLMGYSFhfHJDtcrTXTb1dMngkYP7nxamk+lnd6BMDWISmtncflKtvujzAzccadIhLj8K8w3B2XvlardeUmR5eGuQ1oBEQTVY47j8u71zslYRDvZueszyqjlRsZSVSKuo/FkfTNb72c/meLV8vHtt7YgXKm9DMi7qfr7yvqXpV0AXdHbSKwy/QC7qu8mqZSDwwAAAABJRU5ErkJggg==" />
            ) : (
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAYZJREFUSEulV1EWwyAI05vtaD3abta9UqsgxGDXr00dhJBgV2sp5SyrJzghS3bdnUI/k1S1XNuvnxF7A0Q7ChNzJhBeFXlBpU+c4UznfImw8jwZGvezm4oDzYza0rH1QUv7vBM2a4BAPdPraVQt16RqVfHhcLs8pAW2nMN+xXYyenQtQAXi9Wp9H1V8Q1OJr0NnX+CkhidES6kev3dM0IKAMm+nNtJ00YFOF9MOn15XHDCFesxpD3hrwUzFKtBnL+is2YevHuU7UyRNN8991ohLwhAkbtu1uolU2RInDqKlFe41pvI8dvIJPuyW7lGenl006c9WpAmqkR7ILbjcBoKdwcvbxaD0H5lhaax97HxExkoGY2+HqleqdKpGVsEzJMA7ucfMaqVzaKfcVAogOdsiH6trLMNfv2aR5SVeYmSa6uHs2IAUzqmNqYSpDu6WscTsBManb5kbFJZqBGL2BG0QMVFcGRvehLz2d4O/gfB+w+GNIGihETvx7J3d205LWvD4XOYhmz83KMcq54zqNwAAAABJRU5ErkJggg=="
                alt="menu icon"
              />
            )}
            <div className="openmenu"> {openMenu ? "close" : "menu"}</div>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
