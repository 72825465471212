import React, { useEffect, useState, useRef, useCallback } from 'react';
import './Projects.css';
import { SumArray } from '../../helper';
import { projects } from '../../helper/data';
import Card from './Card/Card';

const tabs = [
    { name: "All" },
    { name: "Web" },
    { name: "UI/UX" },
    // { name: "Apps" }, 
];

const Projects = () => {
    const [displayableProjects, setDisplayableProjects] = useState(projects);
    const [activeIndex, setActiveIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const [indicatorWidth, setIndicatorWidth] = useState(0);
    const itemsEls = useRef([]);

    useEffect(() => {
        const activeElement = itemsEls.current[activeIndex];
        if (activeElement) {
            setOffset(SumArray(itemsEls.current.slice(0, activeIndex).map(item => item.offsetWidth)));
            setIndicatorWidth(activeElement.offsetWidth);
        }
    }, [activeIndex]);

    const setProjects = useCallback((category) => {
        setDisplayableProjects(
            category === "All" 
                ? projects 
                : projects.filter(item => item.category?.toLowerCase() === category.toLowerCase())
        );
    }, []);

    return (
        <section id='projects'>
            <div className="section__wrapper projects__container">
                <div className="section__header center">
                    <h2 className="title">Projects</h2>
                </div>
                <nav>
                    {tabs.map((tab, index) => (
                        <button
                            ref={el => itemsEls.current[index] = el}
                            onClick={() => {
                                setActiveIndex(index);
                                setProjects(tab.name);
                            }}
                            key={index}
                        >
                            {tab.name}
                        </button>
                    ))}
                    <span
                        className="active__indicator"
                        style={{
                            left: `${offset}px`,
                            width: `${indicatorWidth}px`
                        }}
                    />
                </nav>

                <div className="card__container">
                    {displayableProjects.map((project, index) => (
                        <Card 
                            title={project.title}
                            image={project.image}
                            data={project.data}
                            stack={project.stack}
                            demoLink={project.data.demoLink}
                            key={project.id} // Cambiado a project.id
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
