import React  from "react";
import "./MobileNav.css"

const MobileNav = ({ isOpen, toggleMenu}) => {
    return (
        <>

        <div className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}>

            <div className="mobile-menu-container">
                <a className="logo" href="#">Valens <span>Code</span></a>

                

                <ul>
                <li>
              <a className="menu-item" href="#projects">
                Proyects
              </a>
            </li>

            <li>
              <a className="menu-item" href="#about">
                About Me
              </a>
            </li>
 
            <li>
              <a className="menu-item" href="#Contactme">
                Contact Me
              </a>
            </li>

                    <button className="contact-btn" onClick={() =>{window.location.href ="https://docs.google.com/forms/d/e/1FAIpQLSd4g7YadmbjPTxSHaGSxtUXh4E4yXP9Y4rlG47XJ6ZIb7nknA/viewform"}}>Hire Me</button>

                </ul>
            </div>
            </div>
        </>
    )
}
 
  
        
    



export default MobileNav