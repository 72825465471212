export const SKILLS = [
    {
        title: "Frontend",
        icon: "/assets/img/ux.png",
        skills: [
            { skill: "HTML5", percentage: "90%" },
            { skill: "CSS3 (Sass/SCSS)", percentage: "90%" },
            { skill: "JavaScript", percentage: "75%" },
            { skill: "React.js", percentage: "70%" },
        ]
    },
    {
        title: "Backend",
        icon: "/assets/img/cpu.png",
        skills: [
            { skill: "Node.js", percentage: "70%" },
            { skill: "Express.js", percentage: "65%" },
            { skill: "PHP", percentage: "70%" },
        ]
    },
    {
        title: "Tools",
        icon: "/assets/img/service.png",
        skills: [
            { skill: "Git & GitHub", percentage: "85%" },
            { skill: "Visual Studio Code", percentage: "85%" },
            { skill: "Webpack", percentage: "70%" },
            { skill: "Responsive Design", percentage: "85%" },
        ]
    },

   
];



export const WORK_EXPERIENCE = [
    {
        title: "Computer Technician at BellssevenStars",
        date: "Febraury 2022 - Present",
        responsibilities: [
            "Repair software problems, assemble and disassemble desktop computers",
            "Implemented advanced CSS animations and transitions to enhance the overall user experience.",
            
            "Technical support in person or via Teamviewer. Respond to problems and offer quick solutions"
        ]
    },
    {
        title: " Freelancer Junior Web Developer",
        date: "June 2022 - Present",
        responsibilities: [
            "Developed and maintained client websites, ensuring high-quality standards and timely project delivery.",
            "Implemented advanced CSS animations and transitions to enhance the overall user experience.",
            "Worked closely with the design team to create pixel-perfect, cross-browser-compatible web pages.",
            "Conducted code reviews and mentored junior developers in adopting best coding practices."
        ]
    },

        {
            title: "Looking for Internship at ()",
            date: "January 2024 - Present",
            responsibilities: [
                "I am seeking to contribute to the implementation of frontend features using React.js.",
                "Learn performance optimization tasks.",
                "I want to collaborate with senior developers to troubleshoot and resolve coding issues.",
                "Acquire hands-on experience with version control systems, specifically Git, in a collaborative development environment."
            ]
        }
        
];





export default  SKILLS;
