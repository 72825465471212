import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <div className="footer"> <p> © 2024 All Rights Reserved </p><span className='SpanMidle'>   Valens Bayard</span>
     <span className='SpanRight'> Designed By Valens Bayard</span>
    </div>
  )
}

export default Footer