import React, { useState } from 'react';
import "./Card.css";
import { AiOutlineShareAlt } from 'react-icons/ai';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaTiktok } from 'react-icons/fa';

const message = "Check out this amazing web developer's latest project! 🚀 Click the link to see the innovative design and functionality that sets them apart from the rest. Don't miss out on this impressive work!"; 

const socialHandles = [
    { name: "Facebook", icon: <FaFacebook className="social-icon" />, link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(message + window.location.href)}` },
    { name: "Twitter", icon: <FaTwitter className="social-icon" />, link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(message + window.location.href)}` },
    { name: "LinkedIn", icon: <FaLinkedin className="social-icon" />, link: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(message + window.location.href)}` },
    { name: "WhatsApp", icon: <FaWhatsapp className="social-icon" />, link: `https://api.whatsapp.com/send?text=${encodeURIComponent(message + window.location.href)}` },
    { name: "TikTok", icon: <FaTiktok className="social-icon" />, link: `https://www.tiktok.com/share?url=${encodeURIComponent(window.location.href)}` }, // TikTok no soporta mensajes personalizados en los enlaces
];

const Card = (props) => {
    const [openStackExpandBar, setOpenStackExpandBar] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);

    // Función para manejar el clic en "View More"
    const handleViewMoreClick = () => {
        setOpenStackExpandBar(prev => !prev);
        setIsMouseOver(true);
    };

    // Función para manejar la salida del mouse
    const handleMouseLeave = () => {
        if (openStackExpandBar) {
            setOpenStackExpandBar(false);
            setIsMouseOver(false);
        }
    };

    // Función para manejar el clic en el botón de compartir
    const handleShareClick = () => {
        setShareOpen(prev => !prev);
    };

    return (
        <div 
            className='card' 
            onMouseEnter={() => setIsMouseOver(true)} 
            onMouseLeave={handleMouseLeave}
        >
            <div className='picture'>
                <img src={props.image} alt={props.title} />
            </div>
            <div className='card__details'>
                <div className='card__details__top'>
                    <h2 className='title'>{props.title}</h2>
                </div>
                <div className='card__details__middle'>
                    <p className='description'>
                        {props.data.description}
                    </p>
                </div>
                <div className='card__details__button'>
                    <div className='stack__container'>
                        <div className="stack__left">
                            Stack
                        </div>
                        <div className="stack__right">
                            <div className="stack__box__container">
                                {props.stack.map((list, index) => {
                                    if (index < 4) {
                                        return (
                                            <div className="stack__box" key={index}>
                                                <div className="stack__icon__container">
                                                    <span className="stack__icon" style={{ color: list.iconColor }}>
                                                        {list.icon}
                                                    </span>
                                                    <span className="stack__name">
                                                        {list.name}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                            {props.stack.length > 4 && (
                                <div className="stack__view__more">
                                    <div 
                                        className="more__btn" 
                                        onClick={handleViewMoreClick}
                                    >
                                        View More
                                    </div>
                                    <div 
                                        className={`stack__expand__box ${openStackExpandBar ? "open__stack__expand_box" : ""}`} 
                                        style={{ display: openStackExpandBar ? 'block' : 'none' }}
                                        onMouseEnter={() => setIsMouseOver(true)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <h3 className='title'>More Stack Used</h3>
                                        <div className="stack__box__container">
                                            {props.stack.map((list, index) => {
                                                if (index >= 4) {
                                                    return (
                                                        <div className="stack__box" key={index}>
                                                            <div className="stack__icon__container">
                                                                <span className="stack__icon" style={{ color: list.iconColor }}>
                                                                    {list.icon}
                                                                </span>
                                                                <span className="stack__name">
                                                                    {list.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="button__container">
                        <a href={props.demoLink} target='_blank' className='btn btn__primary' rel="noopener noreferrer">Demo</a>
                        <div className="btn__share" onClick={handleShareClick}>
                            <AiOutlineShareAlt />
                        </div>
                        {shareOpen && (
                            <div className="share__box">
                                {socialHandles.map((handle) => (
                                    <a key={handle.name} href={handle.link} target="_blank" rel="noopener noreferrer">
                                        {React.cloneElement(handle.icon, { className: 'social-icon' })}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
