import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contact-form-content">
        <form action="/process_contact_form.php" method="post">
        <div className="name-container">
          <input type="text" name='firstname' placeholder='FirstName' required />
          <input type="text" name='lastname' placeholder='LastName' required /> 
        </div>
        <input type="email" name='email' placeholder='Email' required />
        <textarea name='message' placeholder='Message' rows="5" required></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default ContactForm;
