import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about">
      <div className="section__wrapper about__container">
        <div className="me__container blur-effect">
          <div className="photo__container">
            <img src="/assets/img/photo2.jpg" alt="About Me"   className='photo__img'/>
          </div>
        </div>
        <div className="section__header">
          <h2 className="primary__title">About Me</h2>
          <h1 className="title">
            I am <span className="color__primary">Valens Bayard</span>
          </h1>
          <p className='text-about'>
            Experienced Junior Full Stack Developer with a passion for creating dynamic, intuitive, and responsive applications. Proficient in multiple programming languages and frameworks. Strong problem-solving and analytical skills
            and a track record of delivering high-quality code on time and on budget.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
