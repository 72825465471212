import React from 'react';
import "./Contactme.css";
import ContactInfoCard from './ContactInfoCard/ContactInfoCard';
import ContactForm from './ContactInfoCard/ContactForm/ContactForm';

const Contactme = () => {
  return (
    <section id="Contactme" className="contact-container">
        <h5 className='section__header center title'>Contact Me</h5>

        <div className="contact-content">
            <div style={{ flex: 1 }}>
               <ContactInfoCard
                 iconUrl="../../assets/img/email.svg"
                 text="Val3nsBayard@gmail.com"
               />
               <ContactInfoCard
                 iconUrl="../../assets/img/github.svg"
                 text="https://github.com/Val3ns"
               />
            </div>
            <div style={{ flex: 1 }}>
              <ContactForm/>
            </div>
        </div>
    </section>
  );
}

export default Contactme;
