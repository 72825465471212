import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import './App.css'
import Skills from "./components/Skills/Skills";
import { WorkExperience } from "./components/WorkExperience/WorkExperience";
import Contactme from "./components/Contactme/Contactme";
import Footer from "./components/footer/Footer";
import Projects from "./components/Proyects/Projects";
import About from "./components/Aboutme/About";


const App = () => {
    return (
        <>
            <Navbar />
            <div className="container">
                <Hero />
                <Projects/>
                <About/>

                <div id="WorkExperience" ></div>

                <WorkExperience/>
                <Skills/>
                <Contactme/>
            </div>
            <Footer/>  
        </>
    );
};

export default App;
