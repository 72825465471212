import React  from "react";
import './Hero.css'

const Hero = () => {
    return (
        <section className="hero-container">
            <div className="hero-content">
                <h2>Hello My name Is <span className="herrospan">Valens Bayard</span></h2>
                <p>
                  Frontend Developer. Do you need a Website? Contact me now  <span className="herrospan">to Build your website</span>!
                </p>



                
                <nav className="flex flex-wrap gap-4 mt-8"> 
                    <a href="https://linktr.ee/Val3ns"><svg class="size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"></path><path d="M3 6l9 6l9 -6"></path><path d="M15 18h6"></path><path d="M18 15l3 3l-3 3"></path></svg>Contact Me</a> 
                    <a className="link" href="https://www.linkedin.com/in/valens-bayard/"> <svg class="size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path><path d="M8 11l0 5"></path><path d="M8 8l0 .01"></path><path d="M12 16l0 -5"></path><path d="M16 16v-3a2 2 0 0 0 -4 0"></path></svg> LinkedIn</a></nav>
            </div>


            <div className="hero-img">
                <div>
                    <div  className="tech-icon">
                        <img src="/assets/img/WordPress.svg" alt="" />
                    </div>

                    <img className="tech-Photo" src="/assets/img/photo1.jpg" alt="" />
                </div>

                <div>
                <div id="Skills" className="tech-icon">
                        <img src="/assets/img/Wix.svg" alt="" />
                    </div>
                  
                    <div className="tech-icon">
                        <img src="/assets/img/shopify.svg" alt="" />
                    </div>
                    <div className="tech-icon">
                        <img src="/assets/img/react.svg" alt="" />
                    </div>
                    <div className="tech-icon">
                        <img src="/assets/img/html.svg" alt="" />
                    </div>
                   
                </div>
                     
            </div >
            

          
        </section>
    )
}

export default Hero