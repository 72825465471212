import { DiCss3, DiHtml5, DiReact, DiWordpress } from "react-icons/di";
import { FaNodeJs, FaWix } from "react-icons/fa";
import { SiCss3, SiExpress, SiMongodb, SiReact, SiShopify, SiSquarespace, SiWebflow, SiFiverr } from "react-icons/si";
import { AiFillGithub, AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import { CgFigma } from "react-icons/cg";
import { TbBrandReactNative } from "react-icons/tb";

export const menu = [
    { name: "About" },
    { name: "Services" },
    { name: "Skill" },
    { name: "Projects" },
    { name: "Testimonial" },
    { name: "Contact" },
];

export const projects = [
    { 
      id: 1,
      title: 'Non-Profit-Web',
      image: '/assets/img/NONPROFIT.jpg',
      category: 'Web', // Añadido
      data: {
         description: 'Ready to elevate your nonprofit’s impact? Launch a high-end website that captivates donors and drives significant fundraising growth. Start making a difference today—prices from $350.',
         demoLink: 'https://charitfix.vercel.app/theme/index.html',
      },
      stack: [
        { name: 'WordPress', icon: <DiWordpress />, iconColor: 'skyblue' },
        { name: 'Wix', icon: <FaWix />, iconColor: 'skyblue' },
        { name: 'HTML5', icon: <DiHtml5 />, iconColor: 'red' },
        { name: 'CSS', icon: <DiCss3 />, iconColor: 'blue' },
        { name: 'Shopify', icon: <SiShopify />, iconColor: 'green' },
        { name: 'SquareSpace', icon: <SiSquarespace /> },
      ]
    },
    {
      id: 2,
      title: 'E-Commerce Application',
      image: '/assets/img/ecommerce.jpg',
      category: 'Web', // Añadido
      data: {
        description: 'Enhance your online shopping experience with our E-Commerce Application UI design project. Seamlessly blending aesthetics with functionality, our intuitive interface offers easy navigation, personalized recommendations, and secure transactions. Prices starting from $550.',
        demoLink: 'https://sagitta-5.myshopify.com/',
      },
      stack: [
        { name: 'WebFlow', icon: <SiWebflow />, iconColor: 'skyblue' },
        { name: 'React.js', icon: <SiReact />, iconColor: 'skyblue' },
        { name: 'HTML5', icon: <DiHtml5 />, iconColor: 'red' },
        { name: 'CSS', icon: <DiCss3 />, iconColor: 'blue' },
        { name: 'Shopify', icon: <SiShopify />, iconColor: 'green' },
        { name: 'SquareSpace', icon: <SiSquarespace /> },
        { name: 'WordPress', icon: <DiWordpress />, iconColor: 'skyblue' },
        { name: 'Wix', icon: <FaWix />, iconColor: 'skyblue' },
      ]
    },
    {
      id: 3,
      title: 'Enterprise Landing-Page',
      image: '/assets/img/Enterprise.png',
      category: 'UI/UX', // Añadido
      data: {
        description: 'Ready to take your enterprise to the next level? Elevate your online presence with a cutting-edge website designed specifically for businesses like yours. Prices starting from $850. Contact us to get started!',
        demoLink: 'https://preview.ab-themes.com/shard/',
      },
      stack: [
        { name: 'React.js', icon: <SiReact />, iconColor: 'skyblue' },
        { name: 'HTML5', icon: <DiHtml5 />, iconColor: 'red' },
        { name: 'WebFlow', icon: <SiWebflow />, iconColor: 'skyblue' },
        { name: 'CSS', icon: <DiCss3 />, iconColor: 'blue' },
        { name: 'Shopify', icon: <SiShopify />, iconColor: 'green' },
        { name: 'SquareSpace', icon: <SiSquarespace /> },
        { name: 'WordPress', icon: <DiWordpress />, iconColor: 'skyblue' },
        { name: 'Wix', icon: <FaWix />, iconColor: 'skyblue' },
      ]
    }
];

export const experience = [
    {
        title: "UI/UX",
        data: [
            { skill: "Figma", level: "Experienced" },
            { skill: "Sketch", level: "Intermediate" },
            { skill: "XD", level: "Intermediate" },
        ]
    },
    {
        title: "Frontend Development",
        data: [
            { skill: "HTML", level: "Experienced" },
            { skill: "CSS", level: "Experienced" },
            { skill: "JavaScript", level: "Experienced" },
            { skill: "Tailwind", level: "Intermediate" },
            { skill: "Bootstrap", level: "Experienced" },
            { skill: "React", level: "Experienced" },
            { skill: "React Native", level: "Experienced" },
        ]
    },
    {
        title: "Backend Development",
        data: [
            { skill: "Node JS", level: "Experienced" },
            { skill: "MongoDB", level: "Intermediate" },
            { skill: "PHP", level: "Experienced" },
            { skill: "Python", level: "Intermediate" },
            { skill: "MySQL", level: "Experienced" },
        ]
    },
];
