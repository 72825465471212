import React, { useRef } from 'react';
import "./WorkExperience.css";
import ExperienceCard from './ExperienceCard/ExperienceCard';
import { WORK_EXPERIENCE } from "../../utlis/data";
import Slider from 'react-slick';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';

export const WorkExperience = () => {
    const sliderRef = useRef();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1, 
        arrows: false,
        responsive: [
            {
                breakpoint: 763,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }; 

    const slideRight = () => {
        sliderRef.current.slickNext();
    };

    const slideLeft = () => {
        sliderRef.current.slickPrev(); 
    };
    
    return (
        <section className="experience-container ">

            
            
            <h5 className=' section__header center title'>Work Experience</h5>

            <div className="experience-content">
                <div className="arrow-right" onClick={slideRight}>
                    <span><BsChevronRight /></span>
                </div>
                <div className="arrow-left" onClick={slideLeft}>
                    <span><BsChevronLeft /></span>
                </div>
                
                <Slider ref={sliderRef} {...settings}> 
                    {WORK_EXPERIENCE.map((item) => (
                        <ExperienceCard key={item.title} details={item} />
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default WorkExperience;
